/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
          
        //wow.js initialize - Don't forget to uncomment the enqueue in lib/setup.php at the bottom!
        new WOW().init();
        
        $(function(){$.fn.scrollToTop=function(){$(this).hide().removeAttr("href");if($(window).scrollTop()!="0"){$(this).fadeIn("slow")}var scrollDiv=$(this);$(window).scroll(function(){if($(window).scrollTop()=="0"){$(scrollDiv).fadeOut("slow")}else{$(scrollDiv).fadeIn("slow")}});$(this).click(function(){$("html, body").animate({scrollTop:0},"slow")})}});

        $(function(){
          $("#toTop").scrollToTop();
        });

          $(function(){
              $('.dropdown').hover(function() {
                      $(this).addClass('open');
                  },
                  function() {
                      $(this).removeClass('open');
                  });

              $(".facial-flip").flipster({
                  style: 'carousel',
                  spacing: -0.5,
                  nav: false,
                  buttons: true,
                  scrollwheel: false,
                  touch: false,
                  click: true,
                  loop: true,
                  autoplay: 5000,

                  responsive: [
                      {
                          breakpoint: 1024,
                          settings: {
                              spacing: 1,
                          }
                      }
                  ]
              });
          });

        $(function(){
          $('.dropdown').hover(function() {
                $(this).addClass('open');
              },
              function() {
                $(this).removeClass('open');
              });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    $(function() {
        //caches a jQuery object containing the header element
        var header = $("#shiftnav-toggle-main");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 820 && $("body").hasClass('home')) {
                header.addClass('nav-clear');
            } else {
                header.removeClass("nav-clear");
            }
        });
    });

    // Select all links with hashes
    $('a[href*="#section-preventative"] , a[href*="#section-restorative"] , a[href*="#section-cosmetic"] , a[href*="#section-sedation"]')
    // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

    $("#single-implant-modal").on('hidden.bs.modal', function (e) {
        $("#single-implant-modal iframe").attr("src", $("#single-implant-modal iframe").attr("src"));
    });

    $("#implant-bridge-modal").on('hidden.bs.modal', function (e) {
        $("#implant-bridge-modal iframe").attr("src", $("#implant-bridge-modal iframe").attr("src"));
    });

    $("#implant-dentures-modal").on('hidden.bs.modal', function (e) {
        $("#implant-dentures-modal iframe").attr("src", $("#implant-dentures-modal iframe").attr("src"));
    });

    $("#allon4-modal").on('hidden.bs.modal', function (e) {
        $("#allon4-modal iframe").attr("src", $("#allon4-modal iframe").attr("src"));
    });

})(jQuery); // Fully reference jQuery after this point.


